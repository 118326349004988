const defaultConfig = {
  navigationRoot: '.main-navigation',
  activationElement: '.mobile-navigation-button',
  breakpoint: 568,
  backgroundColor: 'rgba(0, 0, 0, 0.92)'
}

/**
 * Überprüft anhand des Breakpoint ob Mobile Navigation initialisiert werden soll.
 * @param  {Object} c Config-Objekt mit allen Einstellungen
 * @return {Object}   Config-Objekt mit DOM-Elementen statt Query-Strings
 */
function initialize (c) {
  let config = Object.assign(defaultConfig, c)

  config.navigationRoot = document.querySelector(config.navigationRoot)
  config.mobileRoot = config.navigationRoot.cloneNode(true)
  config.toggleButton = document.querySelector(`${config.activationElement} button`)
  config.activationElement = document.querySelector(config.activationElement)

  return config
}

/**
 * Blendet Standard Navigation aus und Hamburger Menü ein
 * @param  {Object} config Config-Objekt
 */
function activateMobileNav (config) {
  if (!window.matchMedia(`(min-width: ${config.breakpoint}px)`).matches) {
    config.activationElement.classList.remove('hidden')
    config.navigationRoot.style.display = 'none'
  } else {
    config.activationElement.classList.add('hidden')
    config.navigationRoot.style.display = ''
    hideNav(document.querySelector('.mobile-navigation'))
  }

  createMobileNav(config)
}

/**
 * Erstellt DOM-Element für mobile Navigation
 * @param  {Object} config Config-Objekt
 */
function createMobileNav (config) {
  if (document.querySelector('.mobile-navigation')) {
    return false
  }
  let mobileNav = document.createElement('div')
  let closeButton = document.createElement('button')

  mobileNav.className = 'mobile-navigation'

  Object.assign(mobileNav.style, {
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: '100vh',
    background: config.backgroundColor,
    'z-index': 10,
    padding: '3em 1.5em 1.5em',
    transform: 'translateY(-100vh)',
    '-webkit-transform': 'translateY(-100vh)',
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    'transition': '.5s transform cubic-bezier(0.5, 0, 0.5, 1.5)',
    '-webkit-transition': '.5s -webkit-transform cubic-bezier(0.5, 0, 0.5, 1.5)'
  })

  Object.assign(closeButton.style, {
    position: 'absolute',
    top: '.5em',
    right: '.5em',
    background: 'transparent',
    padding: 0,
    outline: 'none',
    border: 0
  })

  closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 512 512"><path fill="#fff" d="M340.2 160l-84.4 84.3-84-83.9-11.8 11.8 84 83.8-84 83.9 11.8 11.7 84-83.8 84.4 84.2 11.8-11.7-84.4-84.3 84.4-84.2z"></path></svg>'

  config.navigationRoot.classList.remove('hidden')
  mobileNav.appendChild(config.mobileRoot)
  mobileNav.appendChild(closeButton)

  config.activationElement.addEventListener('click', () => showNav(mobileNav))
  closeButton.addEventListener('click', () => hideNav(mobileNav))

  document.body.appendChild(mobileNav)
}

/**
 * Blendet Mobile Navigation ein
 * @param  {Object} nav DOM-Element der mobilen Navigation
 */
function showNav (nav) {
  if (nav) {
    nav.style['transform'] = 'translateY(0)'
    nav.style['-webkit-transform'] = 'translateY(0)'
  }
}

/**
 * Blendet Mobile Navigation aus
 * @param  {Object} nav DOM-Element der mobilen Navigation
 */
function hideNav (nav) {
  if (nav) {
    nav.style['transform'] = 'translateY(-100vh)'
    nav.style['-webkit-transform'] = 'translateY(-100vh)'
  }
}

/**
 * Mobile Navigation aktivieren
 * @param  {Object} c Config-Objekt
 */
export default function mobileNavigation (c) {
  let config = initialize(c)

  if (!config) return config

  activateMobileNav(config)

  window.addEventListener('resize', (e) => {
    activateMobileNav(config)
  }, {passive: true})
}
