import selectAll from './select-all'
let timer
/**
 * Schließt alle offenen Sub-Menus
 * @param  {Object} e DOM Event welches ausgelöst wurde
 */
function closeSubMenus (e) {
  let openSubMenus
  if (e.target.classList.contains('second-layer')) {
    openSubMenus = document.querySelectorAll('.submenu.second-layer.open')
  } else {
    openSubMenus = document.querySelectorAll('.submenu.open')
  }
  if (openSubMenus.length < 1) return false

  if (e.target.classList.contains('open')) return false

  openSubMenus.forEach((menu) => menu.classList.remove('open'))
}
function clearTimeoutNav () {
  clearTimeout(timer)
}

function mouseOverDorpDown () {
  let dropDownLink = selectAll('.dropdown-field')
  if (dropDownLink !== null) {
    for (let i = dropDownLink.length - 1; i >= 0; i--) {
      dropDownLink[ i ].removeEventListener('mouseover', clearTimeoutNav)
      dropDownLink[ i ].addEventListener('mouseover', clearTimeoutNav)
    }
  }
}

/**
 * Öffnet und schließt Sub-Menus der Navigation
 * @param  {Object} e DOM Event welches ausgelöst wurde
 */
function toggleSubMenu (e) {
  if (e.target.classList.contains('submenu')) {
    clearTimeout(timer)
    if (!e.target.classList.contains('open')) {
      e.preventDefault()
      closeSubMenus(e)
      e.target.classList.toggle('open')
    }
  } else if (e.target.classList.contains('first-layer')) {
    let openSubMenus = document.querySelectorAll('.submenu.open')
    for (let z = openSubMenus.length - 1; z >= 0; z--) {
      openSubMenus[ z ].classList.remove('open')
    }
  }
}

export default function initToggleSubMenu (e) {
  document.body.addEventListener('touchstart', toggleSubMenu)
  document.body.addEventListener('mouseover', toggleSubMenu)
  mouseOverDorpDown()
  let openUL = selectAll('.dropdown.first')
  if (openUL !== null) {
    for (let i = openUL.length - 1; i >= 0; i--) {
      openUL[ i ].addEventListener('mouseout', function (e) {
        if (e.target.classList.contains('dropdown-field')) {
          timer = setTimeout(function () {
            let openSubMenus = document.querySelectorAll('.submenu.open')
            for (let z = openSubMenus.length - 1; z >= 0; z--) {
              openSubMenus[ z ].classList.remove('open')
            }
          }, 300)
        }
      })
    }
  }
}
